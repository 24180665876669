import NwdLocations, { DISCARD_NUMERIC_ID } from '@/router/nwd-router';
import { RouteConfig } from 'vue-router';

// components
import { AppRouteConfig } from './AppRoutes';
import { SystemPermission } from '@/api/api';

export const ADMIN_ROUTES: AppRouteConfig[] = [
  {
    path: '/admin',
    redirect: {
      name: NwdLocations.admin.amsSyncResultsList.name
    },
    component: () => import('@/views/Admin/Admin.vue'),
    meta: {
      requiresAuth: true,
      allowedPermissions: [SystemPermission.ScanToPickAdmin]
    },
    beforeEnter: (to, from, next) => {
      next();
    },
    children: [
      {
        path: 'sync-results',
        name: NwdLocations.admin.amsSyncResultsList.name,
        component: () => import('@/views/Admin/AmsSyncResultsList.vue')
      },
      {
        path: 'printers',
        name: NwdLocations.admin.printers.name,
        component: () => import('@/views/Admin/AdminPrinters.vue')
      },
      {
        path: 'printer-clients',
        name: NwdLocations.admin.printerClients.name,
        component: () => import('@/views/Admin/AdminPrinterClients.vue')
      }
    ]
  }
];
