import { OrderLineItemViewModel } from '@/api/api';
import { GetLaneReservationText } from '@/utilities/lane-utils';
import { Component, Prop, Vue } from 'vue-property-decorator';

export interface ISection {
  reasonCode: string;
  title?: string;
  icon?: string;
  key?: keyof OrderLineItemViewModel;
  laneIndex?: number;
  isConfirm?: boolean;
  reasonForFailed?: string;
}

@Component
export default class QcOrderItemSection extends Vue {
  private readonly Math = Math;

  @Prop({
    required: true
  })
  readonly section!: ISection;
  @Prop({
    type: OrderLineItemViewModel,
    required: true
  })
  readonly lineItem!: OrderLineItemViewModel;

  get outlinedDeny(): boolean {
    return this.section.isConfirm || this.section.isConfirm === undefined;
  }

  get outlinedConfirm(): boolean {
    return !this.section.isConfirm;
  }

  get laneReservation(): string {
    return GetLaneReservationText(this.lineItem, this.section.laneIndex || 0);
  }

  deny(): void {
    this.section.isConfirm = this.section.isConfirm || this.section.isConfirm === undefined ? false : undefined;
    this.$emit('deny', this.section);
  }

  confirm(): void {
    this.section.isConfirm = !this.section.isConfirm ? true : undefined;
    this.$emit('confirm', this.section);
  }
}
