import {
  UserViewModel,
  PickersClient,
  PickerOrderViewModel,
  AuthResultViewModel,
  ScanToPickUserStatusEnum,
  ClockedTimeClient,
  ApplicationUserViewModel,
  UsersClient,
  SystemPermission,
  AmsOrderStatusEnum
} from '@/api/api';

import { Component, Vue, Watch } from 'vue-property-decorator';
import PickerOrderList from '@/views/Picker/Orders/PickerOrderList.vue';
import { AlertAction, AlertNamespace, SnackbarMessage } from '@/store/alert/alert.module-types';
import { AuthAction, AuthGetter, AuthNamespace } from '@/store/auth/auth.module.types';
import { dispatchAlertAction } from '@/store/alert/alert.dispatch';
import { orderStatusString } from '@/filters/order-status-filter';
import { dispatchAuthAction } from '@/store/auth/auth.dispatch';
import NwdLocations from '@/router/nwd-router';

import { UserAction, UserGetter, UserNamespace } from '@/store/user/user.module.types';
import { dispatchUserAction } from '@/store/user/user.dispatch';

import PermissionChecker from '@/views/_components/PermissionChecker.vue';
import UserClockWidget from '@/views/_components/clock-widgets/UserClockWidget.vue';
import TimeStatsWidget from '@/views/_components/clock-widgets/TimeStatsWidget.vue';

@Component({
  name: 'UserProfileCard',
  components: {
    PickerOrderList,
    PermissionChecker,
    UserClockWidget,
    TimeStatsWidget
  },
  filters: {
    orderStatusString
  }
})
export default class UserProfileCard extends Vue {
  public readonly AmsOrderStatusEnum = AmsOrderStatusEnum;
  readonly SystemPermission = SystemPermission;

  @AuthNamespace.Getter(AuthGetter.credential)
  readonly credential!: AuthResultViewModel;

  @AuthNamespace.Getter(AuthGetter.userInfo)
  readonly userInfo!: ApplicationUserViewModel;

  @UserNamespace.Getter(UserGetter.currentStatus)
  readonly currentStatus!: ScanToPickUserStatusEnum;

  dialog = false;
  isSavingStatus = false;
  status: ScanToPickUserStatusEnum | null = null;

  get userFullname(): string {
    return `${this.userInfo?.firstName} ${this.userInfo?.lastName}`;
  }

  orders: PickerOrderViewModel[] = [];
  headers = [
    { text: 'AMS Order #', value: 'amsOrderId' },
    { text: 'Status', value: 'orderStatusCode' },
    { text: '# Cases', value: 'totalCases' }
  ];

  @AlertNamespace.Action(AlertAction.showSnackbar)
  readonly showSnackbar!: (payload: SnackbarMessage) => void;

  readonly pickerClient = new PickersClient();

  get modalTitle(): string {
    const user = this.$data.user as UserViewModel | undefined;
    return user === undefined ? '' : (user.name as string);
  }

  // we need to re-fetch the user data when the user changes
  @Watch('credential', { immediate: true })
  onCredentialChanged(): void {
    this.initData();
  }

  @Watch('currentStatus', { immediate: true })
  onStatusChanged(): void {
    this.status = this.currentStatus;
  }

  public openDialog(): void {
    this.dialog = true;
  }

  initData(): void {
    if (this.credential === null) return;
    this.status = this.currentStatus;

    try {
      this.pickerClient.getNextOrderOfPicker(this.credential.userId || 0, undefined, 5).then((res) => {
        this.orders = res;
      });
    } catch (e) {
      console.error(e);
    }
  }

  async saveChanges(): Promise<void> {
    if (this.status == null) return;
    this.isSavingStatus = true;
    await dispatchUserAction(UserAction.setStatus, this.status);
    this.isSavingStatus = false;

    this.showSnackbar({
      message: 'Status updated successfully',
      timeout: 1000,
      color: 'success'
    });

    this.closeModal();
  }

  closeModal(): void {
    this.$emit(`cancel`);
    this.dialog = false;
  }

  // reset to default if the dialog is closed
  @Watch('dialog')
  resetStatus(): void {
    if (!this.dialog) {
      setTimeout(() => {
        this.status = this.currentStatus;
      }, 200);
    }
  }

  async logout(): Promise<void> {
    this.dialog = false;
    try {
      await dispatchAuthAction(AuthAction.logout);

      // avoid redundant navigation
      if (this.$route.name === NwdLocations.auth.login.name) {
        return;
      } else {
        this.$router.push({ name: NwdLocations.auth.login.name });
      }
    } catch (error) {
      dispatchAlertAction(AlertAction.showError, 'An error occured while logging out, please try again.');
      console.error(error);
    }
  }
}
