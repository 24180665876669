import NwdLocations, { DISCARD_NUMERIC_ID } from '@/router/nwd-router';
import { dispatchAlertAction } from '@/store/alert/alert.dispatch';
import { AlertAction } from '@/store/alert/alert.module-types';

import { NavigationGuardNext, Route, RouteConfig } from 'vue-router';
import { QcAction, dispatchQcAction } from '@/views/QC/_state/qc-module-type';

import QcOrderListVue from '@/views/QC/Orders/QcOrderList.vue';
import QcOrderDetail from '@/views/QC/OrderDetail/QcOrderDetail.vue';
import { AppRouteConfig } from './AppRoutes';
import { SystemPermission } from '@/api/api';

export const QC_ROUTES: AppRouteConfig[] = [
  {
    path: '/qc',
    component: () => import('@/views/QC/QC.vue'),
    meta: {
      requiresAuth: true,
      allowedPermissions: [SystemPermission.ScanToPickQC, SystemPermission.ScanToPickAdmin]
    },
    redirect: {
      name: NwdLocations.qc.orders.name
    },
    children: [
      {
        path: 'orders',
        name: NwdLocations.qc.orders.name,
        component: QcOrderListVue
      },
      {
        path: `orders/:${NwdLocations.qc.orderDetail.idParam}`,
        name: NwdLocations.qc.orderDetail.detail(DISCARD_NUMERIC_ID).name,
        component: QcOrderDetail,
        beforeEnter: async (_to: Route, _from: Route, next: NavigationGuardNext): Promise<void> => {
          const { id } = _to.params;
          try {
            const order = await dispatchQcAction(QcAction.getOrder, {
              orderId: parseInt(id)
            });

            const lineItem = order.orderItems?.sort((a, b) => a.itemNo - b.itemNo).find((i) => !i.isValid);
            await dispatchQcAction(QcAction.getOrderLineItem, {
              orderLineItemId: lineItem?.id ?? order.orderItems![0].id
            });

            next();
          } catch (err: any) {
            const { isSwaggerException, response }: { isSwaggerException: boolean; response: string } = { ...err };
            if (isSwaggerException) {
              const { message } = JSON.parse(response);
              dispatchAlertAction(AlertAction.showError, message);
              next({
                name: NwdLocations.qc.orders.name
              });
            } else {
              throw err;
            }
          }
        }
      }
    ]
  }
];
