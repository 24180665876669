import { OrderLineItemViewModel } from '@/api/api';
import { GetLaneReservationText } from '@/utilities/lane-utils';
import { Component, Prop, Vue } from 'vue-property-decorator';

export interface ISection {
  title?: string;
  icon?: string;
  key: keyof OrderLineItemViewModel;
  laneIndex?: number;
  isConfirm?: boolean;
}

@Component
export default class PickerOrderItemSection extends Vue {
  @Prop({
    required: true
  })
  readonly section!: ISection;
  @Prop({
    type: OrderLineItemViewModel,
    required: true
  })
  readonly lineItem!: OrderLineItemViewModel;
  @Prop({
    type: Boolean,
    default: false
  })
  readonly hideActions!: boolean;
  @Prop({
    type: Boolean,
    default: false
  })
  readonly disableActions!: boolean;
  @Prop({
    type: String,
    default: undefined
  })
  readonly cardColor!: string | undefined;

  get outlinedDeny(): boolean {
    return this.section.isConfirm || this.section.isConfirm === undefined;
  }

  get outlinedConfirm(): boolean {
    return !this.section.isConfirm;
  }

  get laneReservation(): string {
    return GetLaneReservationText(this.lineItem, this.section.laneIndex || 0);
  }

  deny(): void {
    this.section.isConfirm = this.section.isConfirm || this.section.isConfirm === undefined ? false : undefined;
    this.$emit('deny', this.section);
  }

  confirm(): void {
    this.section.isConfirm = !this.section.isConfirm ? true : undefined;
    this.$emit('confirm', this.section);
  }
}
