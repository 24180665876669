import { OrderItemViewModel, OrderLineItemViewModel } from '@/api/api';
import { dispatchOrderAction } from '@/store/order/order-dispatch';
import { OrderAction } from '@/store/order/order-module-types';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import ClerkOrderLineItemNoteDialog from './OrderLineItemNoteDialog.vue';

import { GetLaneReservationsCaseBottleText } from '@/utilities/lane-utils';

@Component({
  components: { ClerkOrderLineItemNoteDialog }
})
export default class ClerkOrderLineItems extends Vue {
  readonly GetLaneReservationsCaseBottleText = GetLaneReservationsCaseBottleText;

  @Prop({
    type: Array,
    required: true,
    default: () => []
  })
  readonly lineItems!: OrderItemViewModel[];

  get expanded() {
    return this.lineItems.filter((x) => x.returnReason);
  }

  headers: DataTableHeader[] = [
    { text: 'Varietal', value: 'varietal' },
    { text: 'Status', value: 'status' },
    { text: 'Vintage', value: 'vintage' },
    { text: 'SKU', value: 'partNo' },
    { text: 'Bottle Size / Configuration', value: 'size' },
    { text: '# Cases', value: 'totalCases' },
    { text: 'Lane/s', value: 'laneReservations' },
    { text: 'Actions', value: 'actions_CUSTOM' }
  ];

  openNoteDialog(item: OrderLineItemViewModel): void {
    dispatchOrderAction(OrderAction.showOrderLineItemNoteDialog, item);
  }
}
