

import { Component, Prop, Vue, Watch, Model } from 'vue-property-decorator';
import { OrderViewModel, CancelOrderInput, PrinterLocationDTO, PickersClient, WarehousePrinterType, ReportsClient } from '@/api/api';
import { AmsOrderStatusEnum } from '@/api/api';
import { dispatchOrderAction } from '@/store/order/order-dispatch';
import { OrderAction } from '@/store/order/order-module-types';
import { dispatchAlertAction } from '@/store/alert/alert.dispatch';
import { AlertAction } from '@/store/alert/alert.module-types';
import { ReportTypeEnum } from './report-type-enum';

@Component
export default class PrintDialog extends Vue {
  public readonly ReportTypeEnum = ReportTypeEnum;
  public readonly requireRule = (value: string | number): boolean | string => {
    return !!value || 'Please select a printer';
  }

  @Model('update:isVisible', { type: Boolean, default: false }) localIsVisible!: boolean;
  @Prop() order!: OrderViewModel;
  @Prop() printerType!: WarehousePrinterType;
  @Prop() reportType!: ReportTypeEnum;

  @Watch('localIsVisible')
  onIsVisibleChanged(value: boolean) {
    if (value) {
      this.loadPrinters();
    }
    this.$emit('update:isVisible', value);
  }


  warehousePrinters: PrinterLocationDTO[] = [];
  targetLocationCode: string = '';
  isLoadingPrinters: boolean = false;
  isDownloadingPDF: boolean = false;

  async loadPrinters(): Promise<void> {
    this.isLoadingPrinters = true;
    try {
      const pickersClient = new PickersClient();
      this.warehousePrinters = await pickersClient.getPrintLocations(this.order.id, this.printerType);
      this.targetLocationCode =
        this.warehousePrinters.filter((x) => x.isDefault && x.isOnline)?.[0]?.locationCode ?? '';

    } catch (error) {
      dispatchAlertAction(AlertAction.showError, 'Failed to load printers.');
      console.error(error);
    } finally {
      this.isLoadingPrinters = false;
    }
  }

  public async downloadPDF(): Promise<void> {
    const reportsClient = new ReportsClient();
    let fileResponse;
    try {
      let fileName = 'report.pdf';
      if (this.reportType == ReportTypeEnum.PalletTag) {
        fileName = `PalletTagReport-${this.order.amsOrderId.toFixed(1).replaceAll('.', '-')}.pdf`;
        this.isDownloadingPDF = true;
        fileResponse = await reportsClient.getPalletTagReportPdf(this.order.id!);

      } else if (this.reportType == ReportTypeEnum.PickTicket) {
        fileName = `PickTicketReport-${this.order.amsOrderId.toFixed(1).replaceAll('.', '-')}.pdf`;
        this.isDownloadingPDF = true;
        fileResponse = await reportsClient.getPickTicketReportPdf(this.order.id!);

      } else {
        dispatchAlertAction(AlertAction.showError, 'Report type not set.');
      }

      if (fileResponse) {
        const url = window.URL.createObjectURL(new Blob([fileResponse.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      }
    } catch (e) {
      dispatchAlertAction(AlertAction.showError, 'Failed to download PDF.');
    } finally {
      this.isDownloadingPDF = false;
    }
  }

  public async print(): Promise<void> {
    if (this.$refs.printForm && (this.$refs.printForm as any).validate()) {
      this.isLoadingPrinters = true;
      try {
        const pickersClient = new PickersClient();
        if (this.reportType == ReportTypeEnum.PalletTag)
          await pickersClient.queuePalletTagPrintJob(this.order.id!, this.targetLocationCode);
        else if (this.reportType == ReportTypeEnum.PickTicket)
          await pickersClient.queuePickTicketPrintJob(this.order.id!, this.targetLocationCode);


        dispatchAlertAction(AlertAction.showSuccess, 'Print job has been successfully queued.');
      } catch (e) {
        dispatchAlertAction(AlertAction.showError, 'Failed to queue print job.');
      } finally {
        this.$emit('update:isVisible', false);
      }
    }
  }

  public async printPalletTags(): Promise<void> {
    if (this.$refs.printForm && (this.$refs.printForm as any).validate()) {
      this.isLoadingPrinters = true;

      try {
        const pickersClient = new PickersClient();
        await pickersClient.queuePalletTagPrintJob(this.order.id!, this.targetLocationCode);
        dispatchAlertAction(AlertAction.showSuccess, 'Pallet tags have been successfully queued for printing.');
      } catch (e) {
        dispatchAlertAction(AlertAction.showError, 'Failed to queue pallet tag print job.');
      } finally {
        this.$emit('update:isVisible', false);
      }
    }
  }
}


