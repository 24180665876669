import { AmsOrderLineItemModel, OrderItemViewModel, OrderLineItemViewModel } from '@/api/api';
import moment from 'moment';

/**
 * Returns the text to display for the lane. If only a single lane is reserved, just displays the lane.
 * @param item
 * @returns
 */
export const GetLaneReservationsCaseBottleText = (item: AmsOrderLineItemModel | OrderItemViewModel) => {
  if (item.laneReservations === undefined || item.laneReservations.length === 0) {
    return 'Unknown';

    // just return the lane if there is only one, we always display the total cases/bottles to pick anyways
  } else if (item.laneReservations.length === 1) {
    return item.laneReservations[0].lane;
  }

  const laneTextArr: string[] = [];
  item.laneReservations?.forEach((lane, i) => laneTextArr.push(GetLaneReservationText(item, i)));
  return laneTextArr.join(', ');
};

export const GetLaneReservationText = (
  item: AmsOrderLineItemModel | OrderItemViewModel | OrderLineItemViewModel,
  reservationIndex: number
) => {
  if (item.laneReservations === undefined || item.laneReservations.length === 0) {
    return 'Unknown';
  } else if (reservationIndex >= item.laneReservations.length) {
    console.warn(`Reservation index ${reservationIndex} is out of bounds for item ${item.id}`);
    return 'Unknown';
  }

  let unitsPerCase = 1;
  // in this case, we need to actually do some math
  // check if item has key 'unitsPercase', then check 'unitsPerCase' if not found

  if (Object.hasOwn(item, 'unitsPercase')) {
    unitsPerCase = (item as any).unitsPercase ?? 1;
  } else if (Object.hasOwn(item, 'unitsPerCase')) {
    unitsPerCase = (item as any).unitsPerCase ?? 1;
  }

  const lane = item.laneReservations[reservationIndex];
  const numCases = lane.reservationQuantity / unitsPerCase;
  const numBottles = lane.reservationQuantity % unitsPerCase;

  const casesStr = Number.isNaN(numCases) ? '?' : numCases.toFixed(0);
  const bottlesStr = Number.isNaN(numBottles) ? '?' : numBottles.toFixed(0);

  // these are actually in PST already, so we need to add a day to get the correct date
  const formattedProdDate = moment(item.laneReservations[reservationIndex].productionDate)
    .add(1, 'day');

  const prodDateStr = formattedProdDate.isValid() ? ` - ${formattedProdDate.format('M/D/YYYY')}` : '';

  return `${lane.lane} (${casesStr} / ${bottlesStr})${prodDateStr}`;
};

export const GetCaseBottleText = (
  item: AmsOrderLineItemModel | OrderItemViewModel | OrderLineItemViewModel,
  reservationIndex: number
) => {
  if (item.laneReservations === undefined || item.laneReservations.length === 0) {
    return 'Unknown';
  } else if (reservationIndex >= item.laneReservations.length) {
    console.warn(`Reservation index ${reservationIndex} is out of bounds for item ${item.id}`);
    return 'Unknown';
  }

  let unitsPerCase = 1;
  // in this case, we need to actually do some math
  // check if item has key 'unitsPercase', then check 'unitsPerCase' if not found

  if (Object.hasOwn(item, 'unitsPercase')) {
    unitsPerCase = (item as any).unitsPercase ?? 1;
  } else if (Object.hasOwn(item, 'unitsPerCase')) {
    unitsPerCase = (item as any).unitsPerCase ?? 1;
  }

  const lane = item.laneReservations[reservationIndex];
  const numCases = lane.reservationQuantity / unitsPerCase;
  const numBottles = lane.reservationQuantity % unitsPerCase;

  const casesStr = Number.isNaN(numCases) ? '?' : numCases.toFixed(0);
  const bottlesStr = Number.isNaN(numBottles) ? '?' : numBottles.toFixed(0);

  return `${casesStr} / ${bottlesStr}`;
};

export const GetProductionDateText = (
  item: AmsOrderLineItemModel | OrderItemViewModel | OrderLineItemViewModel,
  reservationIndex: number
) => {
  if (item.laneReservations === undefined || item.laneReservations.length === 0) {
    return 'Unknown';
  } else if (reservationIndex >= item.laneReservations.length) {
    console.warn(`Reservation index ${reservationIndex} is out of bounds for item ${item.id}`);
    return 'Unknown';
  }

  const formattedProdDate = moment(item.laneReservations[reservationIndex].productionDate);
  const prodDateStr = formattedProdDate.isValid() ? `${formattedProdDate.format('M/D/YYYY')}` : '';
  return `${prodDateStr}`;
};
