import NwdLocations, { DISCARD_NUMERIC_ID } from '@/router/nwd-router';
import { RouteConfig } from 'vue-router';

// components
import Clerk from '@/views/Clerk/Clerk.vue';
import Dashboard from '@/views/Clerk/dashboard/dashboard.vue';
import Pickers from '@/views/Clerk/pickers/Pickers.vue';
import OrderDetail from '@/views/Clerk/order-detail/OrderDetail.vue';
import { AppRouteConfig } from './AppRoutes';
import { SystemPermission } from '@/api/api';

export const CLERK_ROUTES: AppRouteConfig[] = [
  {
    path: '/clerk',
    redirect: {
      name: NwdLocations.clerk.dashboard.name
    },

    component: () => import('@/views/Clerk/Clerk.vue'),
    meta: {
      requiresAuth: true,
      allowedPermissions: [SystemPermission.ScanToPickClerk, SystemPermission.ScanToPickAdmin]
    },
    beforeEnter: (to, from, next) => {
      next();
    },
    children: [
      {
        path: 'orders',
        name: NwdLocations.clerk.dashboard.name,
        component: Dashboard
      },
      {
        path: 'pickers',
        name: NwdLocations.clerk.pickers.name,
        component: Pickers
      },
      {
        path: `orders/:${NwdLocations.clerk.orderDetail.idParam}`,
        name: NwdLocations.clerk.orderDetail.detail(DISCARD_NUMERIC_ID).name,
        component: OrderDetail
      }
    ]
  },
  {
    path: '/incorrect-lanes',
    name: NwdLocations.clerk.incorrectLanes.name,
    component: () => import('@/views/Clerk/incorrect-lanes/IncorrectLanes.vue')
  }
];
