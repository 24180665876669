import { AuthResultViewModel, SystemPermission, ScanToPickUserStatusEnum, UsersClient } from '@/api/api';
import { AuthAction, AuthGetter, AuthNamespace } from '@/store/auth/auth.module.types';
import { UserAction, UserGetter, UserNamespace } from '@/store/user/user.module.types';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { dispatchUserAction } from '@/store/user/user.dispatch';

import UserClockWidget from '@/views/_components/clock-widgets/UserClockWidget.vue';
import TimeStatsWidget from '@/views/_components/clock-widgets/TimeStatsWidget.vue';
import { hasOneScanToPickPermission } from '@/utilities/auth/auth.permissions';
import { dispatchAuthAction } from '@/store/auth/auth.dispatch';
import NwdLocations from '@/router/nwd-router';
import { dispatchAlertAction } from '@/store/alert/alert.dispatch';
import { AlertAction } from '@/store/alert/alert.module-types';

@Component({
  name: 'ClockBlockOverlay',
  components: {
    UserClockWidget,
    TimeStatsWidget
  }
})
export default class ClockBlockOverlay extends Vue {
  readonly ScanToPickUserStatusEnum = ScanToPickUserStatusEnum;

  @AuthNamespace.Getter(AuthGetter.credential)
  readonly credential!: AuthResultViewModel;

  @UserNamespace.Getter(UserGetter.lastClockedTime)
  readonly lastClockedTime!: any;

  @UserNamespace.Getter(UserGetter.currentStatus)
  readonly currentStatus!: ScanToPickUserStatusEnum;

  @Watch('currentStatus', { immediate: true })
  onCurrentStatusChanged(): void {
    this.status = this.currentStatus;
  }

  status: ScanToPickUserStatusEnum | null = null;
  isSavingStatus = false;

  get shouldShowOverlay(): boolean {
    const isOnlyPicker = hasOneScanToPickPermission(
      this.credential?.permissions ?? [],
      SystemPermission.ScanToPickPicker
    );

    return isOnlyPicker && this.currentStatus != null && this.currentStatus !== ScanToPickUserStatusEnum.Online;
  }

  async updateUserStatus(newStatus: ScanToPickUserStatusEnum | null) {
    if (newStatus == null) return;
    this.isSavingStatus = true;
    const result = await dispatchUserAction(UserAction.setStatus, newStatus);
    this.isSavingStatus = false;
  }

  async logout(): Promise<void> {
    try {
      await dispatchAuthAction(AuthAction.logout);

      // avoid redundant navigation
      if (this.$route.name === NwdLocations.auth.login.name) {
        return;
      } else {
        this.$router.push({ name: NwdLocations.auth.login.name });
      }
    } catch (error) {
      dispatchAlertAction(AlertAction.showError, 'An error occured while logging out, please try again.');
      console.error(error);
    }
  }
}
