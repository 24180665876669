import { SystemPermission } from '@/api/api';
import { testPermission } from '@/utilities/auth/auth.permissions';
import { Location } from 'vue-router';

export const DISCARD_NUMERIC_ID = -1;
export const DISCARD_STRING_ID = '';

const NwdLocations = {
  admin: {
    name: 'Admin',
    amsSyncResultsList: {
      name: 'Ams Sync Results'
    },
    printers: {
      name: 'Printers'
    },
    printerClients: {
      name: 'Printer Clients'
    }
  },
  clerk: {
    dashboard: {
      name: 'Clerk Dashboard'
    },
    incorrectLanes: {
      name: 'Incorrect Lanes'
    },
    pickers: {
      name: 'All Pickers'
    },
    orderDetail: {
      idParam: 'id',
      detail: (id: number): Location => ({
        name: 'Order Detail Clerk',
        params: {
          id: `${id}`
        }
      })
    }
  },

  picker: {
    name: 'PickerIndex',
    orders: {
      name: 'Orders'
    },
    orderDetail: {
      name: 'Order Detail Picker Index',
      idParam: 'id',
      orderLineItemIdParam: 'orderItemId',
      detail: (id: number): Location => ({
        name: 'Order Detail Picker',
        params: {
          id: `${id}`
        }
      }),
      orderLineItems: (id: number): Location => ({
        name: 'Picker Order Line Items',
        params: {
          id: `${id}`
        }
      }),
      orderLineItem: (args: { orderId: number; orderItemId: number; partNo?: string }): Location => ({
        name: 'Order Line Item Picker',
        params: {
          id: `${args.orderId}`,
          orderItemId: `${args.orderItemId}`
        },
        query: args.partNo ? { partNo: args.partNo } : {}
      })
    }
  },

  qc: {
    orders: {
      name: 'Orders QC'
    },
    orderDetail: {
      idParam: 'id',
      detail: (id: number): Location => ({
        name: 'Order Detail QC',
        params: {
          id: `${id}`
        }
      })
    }
  },

  auth: {
    login: {
      name: 'Login'
    }
  },

  userManagement: {
    list: {
      name: 'Users'
    },
    add: {
      name: 'Add User'
    }
  },

  home: {
    name: 'Home'
  }
};

const UserHomes = {
  [SystemPermission.ScanToPickAdmin]: NwdLocations.clerk.dashboard.name,
  [SystemPermission.ScanToPickClerk]: NwdLocations.clerk.dashboard.name,
  [SystemPermission.ScanToPickPicker]: NwdLocations.picker.orders.name,
  [SystemPermission.ScanToPickQC]: NwdLocations.qc.orders.name
};

export function GetUserHome(userPermissions: SystemPermission[]): string {
  const possiblePermissions = Object.keys(UserHomes).map((k) => parseInt(k) as SystemPermission);

  for (let i = 0; i < possiblePermissions.length; i++) {
    const p = possiblePermissions[i];
    if (userPermissions.includes(p)) {
      console.log(
        `[${i}] User has permission ${p} and will be redirected to ${UserHomes[p as keyof typeof UserHomes]}`
      );
      return UserHomes[p as keyof typeof UserHomes];
    }
  }

  return NwdLocations.home.name;
}

export default NwdLocations;
